<template>
   <v-row justify="start" align="center" dense>
    <v-col cols="12">
      <h3>Solicitações de matrículas</h3>
      <hr />
    </v-col>
    
   <v-col cols="12">
      <v-data-table
        dense
        :sort-by="['Opção']"
        :headers="headers"
        :items="requests"
        :items-per-page="100"
        class="elevation-1"
        hide-default-footer
        no-data-text="Ainda não há dados disponíveis para a consulta"
        :loading="showLoadingGui"
        loading-text="Buscando solicitações de matrícula..."
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.class }}</td>
            <td class="text-xs-left">{{ props.item.period }}</td>
            <td class="text-xs-left">{{ props.item.priorityDegree }}</td>
            <td class="text-xs-left">{{ props.item.objective }}</td>
            <td class="text-xs-left pa-0">
              <v-select
                dense
                outlined
                class="mt-2"
                :items="conceitos"
                :item-text="'grade'"
                v-model="props.item.opinion"
                return-object
                no-data-text="Não possui notas para seleção"
              ></v-select>
            </td>
            <td class="text-xs-left">{{ props.item.status }}</td>
            <td class="text-xs-left">
              <v-btn
                x-small
                outlined
                color="blue accent-2"
                @click="showCandidate(props.item)"
                ><v-icon small>search</v-icon>
              </v-btn>
            </td>
            <td class="text-xs-left">
              <v-row>
                <v-col cols="6">
                  <v-btn
                    x-small
                    outlined
                    color="green"
                    @click="sendOpinion(props.item)"
                    ><v-icon small>send</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-progress-circular
                    indeterminate
                    color="green"
                    class="ml-2"
                    v-show="props.item.send"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
      </v-data-table>
   </v-col>
   <v-col cols="12">
    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-row justify="center" align="center">{{ snackBarMessage }}</v-row>
    </v-snackbar>
   </v-col>
   </v-row>
</template>


<script>
import { mapState, mapActions } from "vuex";
import * as storage from "@/pages/auth/storage";

export default {
  name: "FacultyOpinion",

  data() {
    return {
      user: {},
      studentsData: [],
      disabled: false,
      requests: [],
      coorientandos: [],
      loading: false,
      search: "",
      showLoadingGui: true,
      showLoadingCoGui: true,
      conceitos: [],
      conceito: {},
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "",
      headers: [
        { sortable: true, text: "Nome", value: "name", align: "left" },
        { sortable: true, text: "Turma", value: "class", align: "left" },
        {
          sortable: true,
          text: "Período",
          value: "period",
          align: "left",
          width: "7%",
        },
        {
          sortable: true,
          text: "Opção",
          value: "priorityDegree",
          align: "left",
          width: "7%",
        },
        { sortable: true, text: "Objetivo", value: "objective", align: "left" },
        {
          sortable: true,
          text: "Conceito",
          value: "opinion",
          align: "left",
          width: "7%",
        },
        { sortable: true, text: "Situação", value: "status", align: "left" },
        { sortable: true, text: "Consultar", value: "consulte", align: "left" },
        { sortable: true, text: "Enviar", value: "send", align: "left" },
      ],
    };
  },

  created() {
    this.user = storage.getLocalData().user;
    this.getAllOpinionGrades();

    if (this.facultyOpinion.length > 0) {
      this.requests = [];
      this.requests = this.getArrayOpinion(this.facultyOpinion);
      this.showLoadingGui = false;
    } else {
      this.getAsyncOpinion(this.user.academicCode);
    }
  },

  methods: {
    ...mapActions("facultyOpinion", ["ActionGetOpinion"]),
    ...mapActions("opinionGrade", ["ActionGetAllOpinionGrade"]),
    ...mapActions("opinionEnrollmentRequest", [
      "ActionPostOpinion",
      "ActionGetOpinionByRequestId",
    ]),

    async getAsyncOpinion(academicCode) {
      try {
        this.disabled = true;
        await this.ActionGetOpinion(academicCode);
      } catch (err) {
        console.error(err);
      } finally {
        this.disabled = false;
      }
    },

    async getAsyncOpinionByRequestId(id) {
      try {
        let res = await this.ActionGetOpinionByRequestId(id);
        if (res.status == 200) return res.body.grade;
        else return null;
      } catch (e) {
        console.error(e);
      }
    },

    async getAllOpinionGrades() {
      try {
        this.disabled = true;
        await this.ActionGetAllOpinionGrade();
      } catch (e) {
        console.error(e);
      } finally {
        this.disabled = false;
      }
    },

    async sendOpinion(item) {
      try {
        item.send = true;
        let res = await this.ActionPostOpinion({
          requestId: item.id,
          gradeId: item.opinion.id,
        });
        item.opinion = res.body.grade;
        this.snackbar = true;
        this.snackBarMessage = "Nota enviada com sucesso!";
      } catch (e) {
        console.error(e);
        this.snackbar = true;
        this.snackBarMessage = "Erro ao registrar a nota do aluno!";
      } finally {
        this.disable = false;
        item.send = false;
      }
    },

    getArrayOpinion(requests) {
      let items = [];

      if (requests) {
        requests.map(async (candidate) => {
          let opinion = await this.getAsyncOpinionByRequestId(candidate.id);

          items.push({
            id: candidate.id,
            name: candidate.candidate.name,
            email: candidate.email,
            opinion: opinion,
            priorityDegree: candidate.priorityDegree,
            objective: candidate.objective,
            class:
              candidate.classVO.subject.code + "-" + candidate.classVO.code,
            status:
              candidate.deferred == null
                ? "Em análise"
                : candidate.deferred == false
                ? "Indeferido"
                : "Deferido",
            candidate: candidate,
            period: candidate.classVO.semester + "/" + candidate.classVO.year,
            send: false,
          });
        });
      }
      return items;
    },

    showCandidate(item) {
      let payload = {
        name: "CandidateInfo",
        params: {
          id: item,
        },
        query: {
          readOnly: true,
          username: item.candidate.candidate.email,
        }
      }
      this.$router.push(payload);
    },
  },

  computed: {
    ...mapState("facultyOpinion", ["facultyOpinion"]),
    ...mapState("faculty", ["faculty"]),
    ...mapState("opinionGrade", ["grades"]),
  },
  watch: {
    facultyOpinion(newValue) {
      this.requests = [];
      this.requests = this.getArrayOpinion(newValue);
      this.showLoadingGui = false;
    },

    grades(newValue) {
      this.conceitos = [];
      newValue.map((e) => {
        this.conceitos.push({
          id: e.id,
          grade: e.grade,
          value: e.value,
        });
      });
    },
  },
};
</script>