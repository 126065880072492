var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h3", [_vm._v("Solicitações de matrículas")]),
        _c("hr")
      ]),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              "sort-by": ["Opção"],
              headers: _vm.headers,
              items: _vm.requests,
              "items-per-page": 100,
              "hide-default-footer": "",
              "no-data-text": "Ainda não há dados disponíveis para a consulta",
              loading: _vm.showLoadingGui,
              "loading-text": "Buscando solicitações de matrícula..."
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.name))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.class))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.period))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.priorityDegree))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.objective))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left pa-0" },
                        [
                          _c("v-select", {
                            staticClass: "mt-2",
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.conceitos,
                              "item-text": "grade",
                              "return-object": "",
                              "no-data-text": "Não possui notas para seleção"
                            },
                            model: {
                              value: props.item.opinion,
                              callback: function($$v) {
                                _vm.$set(props.item, "opinion", $$v)
                              },
                              expression: "props.item.opinion"
                            }
                          })
                        ],
                        1
                      ),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.status))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-small": "",
                                outlined: "",
                                color: "blue accent-2"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showCandidate(props.item)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("search")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        outlined: "",
                                        color: "green"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.sendOpinion(props.item)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("send")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-progress-circular", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: props.item.send,
                                        expression: "props.item.send"
                                      }
                                    ],
                                    staticClass: "ml-2",
                                    attrs: { indeterminate: "", color: "green" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-snackbar",
            {
              attrs: { timeout: _vm.timeout },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _c("v-row", { attrs: { justify: "center", align: "center" } }, [
                _vm._v(_vm._s(_vm.snackBarMessage))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }